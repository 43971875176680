import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
    <Layout>
        <div className="container">
            <h1>404 Error</h1>
            <p>
                This page is not found
                {' '}
                <span role="img" aria-label="img">😭</span>
            </p>
            <a href="/">Go back to EverTool</a>
        </div>
    </Layout>
);

export default NotFoundPage;
